.App {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.center {
    text-align: center;
}

.document-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.documents-selection {
    background-color: #ededed;
    padding: 8px;
    cursor: pointer;
    width: 16em;
    height: 24em;
    margin: 1em;
    box-shadow: 1px 1px 8px 0px #cdcdcd;
    transition: all 0.3s;
}

.documents-selection:hover {
    box-shadow: 1px 1px 8px 0px #858585;
}

.documents-selection h4 {
    text-align: center;
    margin: 2px;
}

.document-image {
    max-width: 100%;
    max-height: 100%;
}

.viewer {
    height: 100%;
}

.viewer-title {
    height: 6%;
    position: relative;
}

.viewer-back {
    font-size: 24pt;
    position: absolute;
    left: 12px;
    top: 0px;
    bottom: 0px;
    cursor: pointer;
    user-select: none;
    line-height: 2;
}

.viewer-fullscreen {
    font-size: 24pt;
    position: absolute;
    right: 12px;
    top: 0px;
    bottom: 0px;
    cursor: pointer;
    user-select: none;
    line-height: 2;
}

.viewer-title {
    margin: 0;
    text-align: center;
    line-height: 3;
}

.viewer-content {
    display: flex;
    width: 100%;
    height: 94%;
    overflow: auto;
    background-color: #f5f5f5;
    box-shadow: inset 0px -2px 8px 0px #9e9e9e;
    user-select: none;
}

.viewer-slides {
    display: flex;
    flex-direction: column;
    overflow: auto;
    box-shadow: 2px 8px 8px 0px #9e9e9e;
    width: 180px;
}

.viewer-slides img {
    margin: 4px 0px;
    max-width: 8em;
    max-height: 8em;
    padding: 3px;
    cursor: pointer;
}

.viewer-slides img:hover {
    border: 3px solid #00bcd4;
    padding: 0;
}

.viewer-slides img.active {
    border: 3px solid #03a9f4;
    padding: 0;
}

.viewer-slide {
    display: flex; 
    margin: 0px;
    transition: all 0.2s ease;
}

.viewer-slide.active {
    background-color: lightgrey;
}

.viewer-slide-nmb {
    flex: 1 1 20px;
    padding: 4px;
}

.viewer-slide-img {
    flex-basis: 100%;
    text-align: center;
}

.viewer-image {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 1em;
}

.viewer-image-content {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 97%;
}

.image-full {
    max-width: 100%;
    max-height: 100%;
}

.viewer-pager {
    display: flex;
    user-select: none;
}

.viewer-arrow {
    font-size: 1.7em;
    cursor: pointer;
}

.viewer-page {
    padding: 8px;
}